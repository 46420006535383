import { useState, useContext, useEffect } from 'react';
import { Button } from "@/components/ui/button";
import { IMAGE_URL } from "@/config/appConfig";
import { AppContext } from "@/context/context";
import { Dealer } from "@/interfaces/dealer.interface";
import { Product, ItemMakes } from "@/interfaces/product.interface";
import {
  QueryKeys,
  addItemToWishlist,
  addItemsToCart,
  addItemsToWishlistDealer,
  fetchItem,
  viewedProducts,
  specialsProducts,
} from "@/lib/queries";
import { cn } from "@/lib/utils";
import { getPriceAgainstLevel } from "@/utils/helpers/getPriceAgainstLevel";
import { Heart, Loader, Minus, Plus, ShoppingCart } from "lucide-react";
import { toast } from 'react-toastify';
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate } from "react-router";
import ReactImageZoom from 'react-image-zoom';

interface ProductDetailProps {
  productCode: string;
  onClose?: () => void;
}

const ProductDetail = ({ productCode, onClose }: ProductDetailProps) => {
  const navigate = useNavigate();
  const [quantity, setQuantity] = useState(0);
  const [isHovered, setIsHovered] = useState(false);
  const queryClient = useQueryClient(); // React Query Client

  const { dispatch, state } = useContext(AppContext);

  const dealerUser = state.user && (state.user as Dealer);
  const isDealer = dealerUser && dealerUser.account;

  const [specialPrice, setSpecialPrice] = useState<number | null>(null);
  const [originalPrice, setOriginalPrice] = useState<number | null>(null);

  const { data: item } = useQuery<{ singleItem: Product; item_makes: ItemMakes[] }>(
    [QueryKeys.ITEM, productCode], 
    () => fetchItem(productCode as string), 
    {
      enabled: !!productCode,
      refetchOnWindowFocus: false,
      onSuccess(data) {
        if (isDealer) {
          setQuantity(1); // Default to 1 for dealers
        } else {
          setQuantity(data.singleItem.onhand > 0 ? 1 : 0);
        }
      },
    }
  );

  useQuery([QueryKeys.VIEWED_PRODUCTS, productCode], () => viewedProducts(productCode as string), {
    enabled: !!productCode,
    refetchOnWindowFocus: false,
  });

  const wishlistMutation = useMutation(addItemToWishlist, {
    onSuccess(data) {
      if (data.status === "saved") {
        toast.success("Item added to wishlist");
      }
      if (data.status === "exist") {
        toast.success("Item already exists in wishlist");
      }
    },
  });

  const dealerWishlistMutation = useMutation(addItemsToWishlistDealer, {
    onSuccess(data) {
      if (data.status === "saved") {
        toast.success("Item added to wishlist");
      }
      if (data.status === "exist") {
        toast.success("Item already exists in wishlist");
      }
    },
  });

  const addToWishlist = () => {
    if (!state.user) return navigate("/sign-in");

    if (isDealer) {
      dealerWishlistMutation.mutate({ item: productCode as string, user: state.user.id });
    } else {
      wishlistMutation.mutate({ item: productCode as string, user: state.user.id });
    }
  };

  // Mutate the cart and invalidate the quantity query to trigger an update in the header
  const { mutate, isLoading } = useMutation(addItemsToCart, {
    onSuccess(data) {
      if (data.status === "saved") {
        toast.success("Item added to cart successfully.");
        queryClient.invalidateQueries(QueryKeys.CART); // Invalidate the cart query
        queryClient.invalidateQueries(QueryKeys.ITEMS_QUANTITY); // Invalidate cart quantity query to trigger refetch in the header
        onClose && onClose();
      }
    },
  });

  const addToCart = () => {
    if (item) {
      if (!isDealer) {
        // For non-dealers, check stock quantity
        if (quantity > item.singleItem.onhand) {
          return toast.error("Quantity exceeds available stock");
        }
  
        if (quantity < 1) {
          return toast.error("Quantity must be greater than 0");
        }
      }
  
      const cartType = isDealer ? 2 : 1;
  
      if (!state.user) {
        dispatch({
          type: "ADD_TO_CART",
          payload: {
            item: item.singleItem,
            quantity: quantity,
            cart: state.cart,
          },
        });
        navigate("/sign-in");
      } else {
        mutate({
          item: productCode as string,
          user: state.user.id,
          quantity: quantity,
          type: cartType,
        });
      }
    }
  };

    // Fetch special products for non-dealers
    useQuery(QueryKeys.SPECIAL, specialsProducts, {
      enabled: !isDealer, // Fetch only if user is not a dealer
      onSuccess: (data) => {
        const specialItem = data?.item?.find((specialProduct) => specialProduct.item === productCode);
        if (specialItem) {
          setSpecialPrice(specialItem.sale_price || null);
          setOriginalPrice(specialItem.org_price || null);
        }
      },
    });

  // Handle price fetching with special price priority for non-dealers
  const fetchPrice = (product: Product) => {
    if (isDealer) {
      // Dealer-specific pricing logic
      const dealerType = Number(dealerUser?.dealer_info?.pricecode) || 0;
      return getPriceAgainstLevel(dealerType, product);
    } else {
      // Non-dealer pricing logic
      if (specialPrice) {
        return specialPrice;
      } else if (product.onsale) {
        return product.pricel // Apply 10% discount for on-sale items is done in the price tag section
      } else {
        return product.pricel; // Regular price as fallback
      }
    }
  };

  const zoomProps = {
    width: 400,
    zoomPosition: "right",
    img: IMAGE_URL + (isHovered ? item?.singleItem.big : item?.singleItem.img_path),
  };

  const [isTall, setIsTall] = useState(false);

  const [isImageLoaded, setIsImageLoaded] = useState(false);

  useEffect(() => {
    if (item?.singleItem.img_path) {
      const img = new Image();
      img.src = IMAGE_URL + item.singleItem.img_path; // Load the image
      img.onload = () => {
        const aspectRatio = img.height / img.width;
        setIsTall(aspectRatio > 1.8); // Set `true` if the image is tall
        setIsImageLoaded(true); // Mark the image as loaded
      };
    }
  }, [item]);
  

  useEffect(() => {
    if (item?.singleItem.img_path) {
      const img = new Image();
      img.src = IMAGE_URL + item.singleItem.img_path; // Load the image
      img.onload = () => {
        const aspectRatio = img.height / img.width;
        setIsTall(aspectRatio > 1.8); // Set `true` if the image is tall
      };
    }
  }, [item]);
  
  

  return (
    <section className="container mx-auto relative">
      <div className="w-full mx-auto px-4 sm:px-6 lg:px-0 h-full max-h-screen overflow-y-auto no-scrollbar">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-16 mx-auto max-md:px-2 h-full">
          <div
            className="img flex items-center cursor-zoom-in"
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
            <div className="h-full flex flex-col justify-start items-center w-full">
              <h4 className="text-[#184178] font-black text-5xl mt-5 mb-10">
                {item && item.singleItem.item}
              </h4>
              {item && isImageLoaded ? (
                  <div
                    className={cn(
                      "relative flex justify-center items-center mx-auto",
                      isTall ? "w-1/3" : "w-full" // Use w-1/3 only for tall images
                    )}
                  >
                    <ReactImageZoom {...zoomProps} />
                  </div>
                ) : (
                  <div className="flex justify-center items-center h-[300px] w-full">
                    <Loader className="w-10 h-10 text-gray-500 animate-spin" /> {/* Placeholder spinner */}
                  </div>
                )}
            </div>
          </div>
          <div className="data w-full lg:pr-8 pr-0 xl:justify-start justify-center flex items-center max-lg:pb-10 xl:my-2 lg:my-5 my-0">
            <div className="data w-full h-full max-w-xl overflow-y-auto max-h-[85vh] pb-24 px-4 no-scrollbar">
              {/* Product Info Section */}
              <div className="mt-4 bg-[#FAFAFA] rounded-xl p-5">
              <h1 className="font-black text-2xl mt-6">{item && item.singleItem.descrip}</h1>
                <p className="text-md mt-9 font-medium">
                  Year fit: {item && item.singleItem.year_from}-{item && item.singleItem.year_end}
                </p>
                <p className="text-md mt-2 font-medium">
                  Make:{" "}
                  {item && item.item_makes && (
                    item.item_makes.map((makeObj, index) => (
                      <span key={makeObj.row_id}>
                        {makeObj.make}
                        {index < item.item_makes.length - 1 && ', '}
                      </span>
                    ))
                  )}
                </p>


                {/* <p className="text-md mt-2 font-medium">Model: {item && item.singleItem.model}</p> */}
                <div className="flex flex-col items-start justify-between">
                  <p
                    className={cn(
                      "text-[#0B8D10] font-semibold mt-2",
                      item && item.singleItem.onhand === 0 && "text-[#FC3333]"
                    )}
                  >
                    In-stock: {item && item.singleItem.onhand}
                  </p>

                      {/* Display ETA when out of stock and ETA exists */}
                  {item && item.singleItem.onhand === 0 && item.singleItem.onshpeta && (
                    <p className="font-medium text-gray-600 mt-2">
                      ETA: {new Date(item.singleItem.onshpeta).toLocaleDateString()}
                    </p>
                  )}

                </div>
                <p
                    className="text-[#2F50FF] font-semibold underline mt-2 cursor-pointer text-sm text-end"
                    onClick={() => navigate(`/shipping-inquiry/${productCode}?inquiry=shipping`)}
                  >
                    Shipping Inquiry
                  </p>
                <p
                  className="text-[#2F50FF] font-semibold underline mt-2 cursor-pointer text-sm text-end"
                  onClick={() => navigate(`/shipping-inquiry/${productCode}?inquiry=eta`)}
                >
                  Inquire ETA
                </p>
              </div>

                {/* Price Tag */}
                <h3 className="text-3xl md:text-5xl text-center md:ml-14 ml-0 font-black p-5 text-[#3D3D3D]">
                  {/* Dealer and Non-Dealer Pricing Logic */}
                  {isDealer ? (
                    <>
                    <p className="text-lg text-red-500 mt-2">MSRP: CAD {item?.singleItem.pricel.toFixed(2)}</p>
                      <span>CAD {item ? fetchPrice(item.singleItem).toFixed(2) : ""}</span>
                    </>
                  ) : (
                    <>
                      {specialPrice ? (
                        <>
                          <span className="line-through">CAD {originalPrice?.toFixed(2)}</span><br />
                          <span className="text-red-500">CAD {specialPrice.toFixed(2)}</span>
                        </>
                      ) : (
                        item?.singleItem.onsale ? (
                          <>
                            <span className="line-through">CAD {fetchPrice(item.singleItem).toFixed(2)}</span><br />
                            <span className="text-red-500">CAD {(fetchPrice(item.singleItem) * 0.9).toFixed(2)}</span>
                          </>
                        ) : (
                          `CAD ${item ? fetchPrice(item.singleItem).toFixed(2) : ""}`
                        )
                      )}
                    </>
                  )}
                </h3>


              {/* Quantity and Action Buttons */}
              <div className="flex items-center justify-center ml-0 md:ml-14 gap-3 px-5">
                <button
                  className="bg-white shadow-lg rounded-md cursor-pointer"
                  onClick={() => {
                    if (quantity > 1 || isDealer) setQuantity(quantity - 1);
                  }}
                >
                  <Minus className="w-10 h-10 text-[#3D3D3D]" />
                </button>
                <span className="bg-white shadow-lg rounded-md h-10 w-10 flex justify-center items-center font-black text-[#3D3D3D] text-2xl">
                  {quantity}
                </span>
                <button
                  className="bg-white shadow-lg rounded-md cursor-pointer"
                  onClick={() => setQuantity(quantity + 1)}
                >
                  <Plus className="w-10 h-10 text-[#3D3D3D]" />
                </button>
              </div>

              {/* Wishlist and Add To Cart Buttons */}
              <div className="flex justify-center items-center ml-15 gap-3 p-5 mb-10">
                <span
                  className="p-2 rounded-xl border border-[#3D3D3D] cursor-pointer mr-4 flex items-center w-[130px] h-[50px]"
                  onClick={() => addToWishlist()}
                >
                  {wishlistMutation.isSuccess ? (
                    <Heart className="w-6 h-6 text-[#FF0000] ml-2" />
                  ) : (
                    <Heart className="w-6 h-6 text-[#3D3D3D] ml-2" />
                  )}
                  <span className="ml-2 text-[#3D3D3D] font-semibold text-center leading-5">Add to Wishlist</span>
                </span>
                
                <Button
                  className="bg-[#184178] rounded-xl text-white font-semibold w-[260px] h-[50px] hover:bg-[#184178]"
                  onClick={addToCart}
                  disabled={isLoading || quantity === 0}
                >
                  {isLoading ? (
                    <Loader className="w-8 h-8 text-white mr-2 animate-spin" />
                  ) : (
                    <ShoppingCart className="w-8 h-8 text-white mr-2" />
                  )}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default ProductDetail;
